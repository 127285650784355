<script setup>
const props = defineProps({
	blok: {
		type: Object,
		required: true
	}
})

const {
	hero: [hero],
	tiles
} = props.blok

useHead({
	bodyAttrs: {
		class: ['page-home']
	}
})
</script>

<template>
	<main
		v-editable="blok"
		class="home"
	>
		<StoryblokComponent :blok="hero" />
		<PageTiles
			:tiles="tiles"
			:event-parent="'Home'"
		/>
	</main>
</template>
